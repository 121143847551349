#app-container {
    min-height: calc(100vh - 180px);
    overflow: hidden;
    background-color: #f5f7fb;
}

.modal-backdrop.show {
    z-index: 1055 !important;
}

.data-grid-container .table-responsive {
    max-height: 70vh;
    overflow: auto;
    position: relative;
}

.datagrid-table {
    border-collapse: separate;
    border-spacing: 0;
}

.datagrid-table thead {
    z-index: 999;
}

.datagrid-table thead th {
    z-index: 999;
    background-color: #ccc;
}

.datagrid-table thead th.head-last-td {
    position: sticky;
    right: 0;
    border-left: 1px solid #aaa;
}

.datagrid-table td.last-td {
    position: sticky;
    right: 0;
    border-left: 1px solid #aaa;
    white-space: nowrap;
    width: 1%;
}

.datagrid-table td:has(.show) {
    z-index: 998;
}

.data-grid-container .table-responsive {
    padding-bottom: 80px;
}