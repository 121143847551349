.select-backgorund-color {
    background-color: #294F75;
    cursor: pointer;
}

.select-backgorund-color:hover {
    background-color: #1C3550;
    box-shadow: 0 3px 15px rgb(0 0 0 / 25%);
}

.select-backgorund-color p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.top-3 {
    top: 3rem !important
}

.modal-index {
    z-index: 1060;
    color: #515151;
    max-height: 60vh;
    overflow: auto;
    border-radius: 3px;
    box-shadow: 0 3px 7px rgb(0 0 0 / 30%);
}

.small-description-text {
    font-size: 0.6rem;
    color: #999;
}

.search-adv .list-group-item {
    border: none !important;
}

.search-adv .list-group-item:hover {
    background-color: #F5F5F5;
}

.search-adv .list-group-item input {
    cursor: pointer;
}

.list-group-item input:focus {
    border: none !important;
    outline: 0;
    box-shadow: none !important;
}

.css-b62m3t-container {
    margin: 5px 0 20px 0;
}

.medium-select-description-text {
    font-size: 0.8rem;
    color: #db9a00;
}

.filter-backgorund-yellow-color, .filter-backgorund-red-color {
    background-color: #ffffff;
}

.filter-backgorund-yellow-color p, .filter-backgorund-red-color p {
    padding: 5px;
    border-radius: 5px;
    margin-top:inherit;
    margin-bottom: inherit;
    font-size: 0.8rem;
    color: black;
}

.filter-backgorund-yellow-color p {
    background-color: #ffc107;
}

.filter-backgorund-red-color p {
    background-color: #ff6a07;
}

.filter-backgorund-red-button-color {
    background-color: #ff6a07 !important;
    border-color: #ff6a07 !important;
}

.stabilize-height div, .stabilize-height input {
    height: 45px;
}

.stabilize-height input {
    border-left: 0;
}

.stabilize-height input:focus {
    border-color: #ced4da;
    box-shadow: 0 0 0 0;
}

.search-adv .dropdown-menu.show {
    width: 100%;
}

.select__menu {
    z-index: 3 !important;
}