.field__reverseExternalEntity, .field__deepExternalEntity {
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 3%);
    background-color: #f0f3f5;
    border-radius: 10px;
    overflow: hidden;
    padding: 5px;
}

.field__reverseExternalEntity .form-label, .field__deepExternalEntity .form-label {
    padding: 4px 8px;
    color: #777;
    font-size: 12px;
}

.field__reverseExternalEntity button, .field__deepExternalEntity button {
    float: right;
}