#sidebar {
    background: #222e3c;
    max-width: 264px;
    min-width: 264px;
    transition: margin-left .35s ease-in-out,left .35s ease-in-out,margin-right .35s ease-in-out,right .35s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    bottom: 0;
    z-index: 1021;
    overflow-y: auto;
}

#sidebar::-webkit-scrollbar{
    display: none;
}

#sidebar[data-sidebar=false] {
    margin-left: -264px;
}

@media only screen and (min-width: 992px) {
    #column-container.sidebar-open {
        margin-left: 264px;
    }
}

.sidebar-logo {
    width: 100%;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
    box-shadow: 0 0 2rem 0 rgba(33, 37, 41, .7);
}

.sidebar-menu {
    width: 100%;
    padding: 0 0;
    margin-top: 0;
    margin-bottom: 30px;
}

a.sidebar-item {
    color: rgba(233, 236, 239, .5);
    text-decoration: none;
    font-size: 13px;
    display: block;
    padding: 12px 10px;
    transition: all .25s ease-in-out;
    border-left: 3px solid;
    border-left-color: transparent;
    margin-left: 8px;
    margin-right: 8px;
}

a.sidebar-item:hover {
    color: #e9ecef;
}

a.sidebar-item svg {
    width: 22px;
    transition: all .25s ease-in-out;
}

a.sidebar-item:hover svg {
    color: #e9ecef;
}

a.sidebar-item.active {
    background: rgba(59, 125, 221, .2);
    border-left-color: #3b7ddd;
    color: #e9ecef;
    border-radius: 10px;
}

a.sidebar-item.active svg {
    color: #e9ecef;
}

.sidebar-header {
    color: #ced4da;
    font-size: 12px;
    padding: 12px 15px;
    display: block;
    margin-top: 15px;
}

.sidebar-folder {
    color: rgba(233,236,239,.5);
    display: block;
    padding: 0;
    margin-left: 8px;
    margin-right: 8px;
}

.sidebar-folder svg {
    width: 22px;
}

.sidebar-foldername {
    color: rgba(233,236,239,.5);
    text-decoration: none;
    font-size: 13px;
    display: block;
    padding: 12px 10px;
    transition: all .25s ease-in-out;
    cursor: pointer;
    border-left: 3px solid;
    border-left-color: transparent;
}

.sidebar-foldername span.chevron {
    float: right;
}

.sidebar-subitems {
    background-color: rgba(256,256,256,0.1);
    display: none;
    border-radius: 10px;
}

.sidebar-subitem {
    padding: 12px 15px;
    color: rgba(233,236,239,.5);
    text-decoration: none;
    font-size: 13px;
    display: block;
    transition: all .25s ease-in-out;
}

.sidebar-subitem:hover {
    color: #e9ecef;
}

a.sidebar-subitem.active {
    background: rgba(59, 125, 221, .088);
    color: #e9ecef;
}

.sidebar-folder.active .sidebar-subitems {
    display: block;
}