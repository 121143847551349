#login-container {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
}

.form-signin {
    width: 370px;
    max-width: 100%;
    padding: 15px 29px;
    margin: auto;
    background-color: #eee;
    border-radius: 8px;
}

.form-signin .checkbox {
    font-weight: 400;
}

.form-signin .form-floating:focus-within {
    z-index: 2;
}

.form-signin input[type="text"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

#inputPassword {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.form-recover {
    width: 370px;
    max-width: 100%;
    padding: 15px 29px;
    margin: auto;
    background-color: #eee;
    border-radius: 8px;
}