@media only screen and (min-width: 992px) {
    #header-navbar {
        height: 75px;
    }
    #header-menu {
        text-transform: uppercase;
        font-size: 12px;
    }
}

#header-navbar {
    box-shadow: 0 0 2rem 0 rgba(33,37,41,.1);
    height: 75px;
}

#header-menu a.nav-link.active {
    color: rgb(11, 165, 236);
    font-weight: 600;
}

#header-menu[data-sidebar=true] {
    margin-left: 264px;
}